<template>
  <el-main class="achievements-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        {{ $t('route.Achievements') }}
      </div>
      <el-row 
        :gutter="20"
        type="flex"
        justify="start"
        class="use-case-row"
        style="margin-left:0; margin-right:0;"
      >
        <el-col 
          v-for="item in achievement"
          :key="item.id"
          class="use-case-col"
        >
          <router-link :to="{ name: item.routerName }">
            <el-card :body-style="{ padding: '0px' }" shadow="hover">
              <div class="card-image-container">
                <img :src="item.displayCover" class="image" aria-hidden="true" alt="">
              </div>
              <div class="card-body">
                <div class="card-title">
                  {{ item.displayName }}
                </div>
                <div>
                  {{ item.description }}
                </div>
              </div>
            </el-card>
          </router-link>
        </el-col>
      </el-row>
    </section>
  </el-main>
</template>

<script>
import { UseCase } from '@/Mixins'
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'Achievements',
  components: {
    MainContain
  },
  mixins: [UseCase]
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.achievements-container {
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
  }
  .use-case-row{
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 1199px){
      justify-content: center;
    }
  }
  .use-case-col{
    flex-basis: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    .card-image-container{
      height: 280px;
      width: 378px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .image {
      max-height: 100%;
      display: block;
      margin: auto;
    }
    .card-body{
      line-height: 1.5;
      padding: 1rem;
      height: 230px;
      .card-title{
        height: 57px;
        font-size: 1.17em;
        font-weight: bold;
        margin-bottom: 0.875em;
      }
    }
  }
}
</style>
